import React from 'react';
import { graphql } from 'gatsby';
import {
  Box,
  Flex,
  Button,
  Heading,
  Container,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { Layout, Profile } from '../components/index';

const ProfessionalsPage = ({ data }) => {
  const team = data.teamProfiles.edges;
  const { title } = team[0].node.frontmatter;
  return (
    <Layout>
      <Box bg="brand.one" py={20}>
        <Container maxW="container.xl">
          <Box>
            <Heading as="h1" size="3xl" fontWeight="light" mb={16} mt={8}>
              Our Team
            </Heading>
          </Box>
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            {team.map((t) => (
              <GridItem colSpan={{ base: '2', lg: '1' }}>
                <Profile profile={t} />
              </GridItem>
            ))}
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
};

export const query = graphql`
  query {
    teamProfiles: allMarkdownRemark(
      filter: { fields: { path: { regex: "/team/" } } }
      sort: { fields: frontmatter___date, order: ASC }
    ) {
      edges {
        node {
          fields {
            path
          }
          frontmatter {
            profileImg {
              childrenImageSharp {
                fixed(width: 120) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            name
            title
            phone
            fax
            bio
            experience
          }
        }
      }
    }
  }
`;

export default ProfessionalsPage;
